//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    middleware: ['auth'],
    auth: 'guest',
    layout:'empty',
    data () {
        return {
            form: {
                email: '',
                password: '',
                role: 'patient',
            },
            
            errorPaswordShown: false,
            passwordShown: false,
        }
    },

    head() {
        return {
            title: '',
        }
    },

    computed: {
        active () {
            return this.$store.state.loginStatus
        },

        validateEmail() {
            return (this.form.email=='')||((/\S+@\S+\.\S+/).test(this.form.email));
        }
    },

    methods: {
        passwordInput () {
            if (this.form.password.length<6&&this.form.password.length>=0) {
                this.errorPaswordShown = true
            }else{
                this.errorPaswordShown = false
            }
        },

        async submitForm() {
            if (!(/\S+@\S+\.\S+/).test(this.form.email)||this.form.password.length<6) {
                return
            }
            if(this.active) {
                try{
                    const register = await this.$api.register(this.form)
                    this.$auth.loginWith('cookie', {
                        data: {
                            email:this.form.email,
                            password:this.form.password
                        }
                    })
                    .then((res) => {
                        this.$toast.success(res.message)
                    })
                    .catch(error => {
                        this.$toast.error(error.response.data.message)
                    })
                }catch (error) {
                    this.$toast.error(error.response.data.message)
                }
            }else{
                this.$auth.loginWith('cookie', {
                    data: this.form
                })
                .then((res) => {
                    this.$toast.success(res.message)
                })
                .catch(error => {
                    this.$toast.error(error.response.data.message)
                })
            }
        },

        notAllowedToLogin() {
            this.$toast.error(`${this.$i18n.t('loginPage.notAllowedToLogin')}`)
        }
    },

    created() {
        this.$metaInfo.title = this.$i18n.t('loginPage.pageTitle')
    }
}
